import React from 'react'
import { Routes, Route } from 'react-router-dom'

// Miscellaneous
import paths from '../../utils/paths'

// Components
import Layout from '../Layout'
import Loader from '../Loader'
import ProtectedRoute from '../ProtectedRoute'

// Pages
const AccountLeasedAssetListPage = React.lazy(() => import('../../pages/Account/AccountLeasedAssetList'))
const AccountLayout = React.lazy(() => import('../../pages/Account/AccountLayout'))
const AccountNotesPage = React.lazy(() => import('../../pages/Account/AccountNotes/AccountNotesLoader'))
const AccountOverviewPage = React.lazy(() => import('../../pages/Account/AccountOverview'))
const AccountPaymentsPage = React.lazy(() => import('../../pages/Account/AccountPayments'))
const AccountPropertyAssetListPage = React.lazy(() => import('../../pages/Account/AccountPropertyAssetList'))
const AccountUsersPage = React.lazy(() => import('../../pages/Account/AccountUsers'))
const AccountAssociatedAccountsPage = React.lazy(() => import('../../pages/Account/AccountAssociatedAccounts'))
const AccountTypePage = React.lazy(() => import('../../pages/AccountTypes'))
const AccountsPage = React.lazy(() => import('../../pages/Accounts'))
const AdminUsersPage = React.lazy(() => import('../../pages/AdminUsers/AdminUsers'))
const AppraiserRegionsPage = React.lazy(() => import('../../pages/BusinessAppraiserRegions'))
const BasicCostPage = React.lazy(() => import('../../pages/BasicCost'))
const CountySettingsPage = React.lazy(() => import('../../pages/CountySettings'))
const HomePage = React.lazy(() => import('../../pages/Home'))
const Login = React.lazy(() => import('../../pages/Login'))
const PageNotFound = React.lazy(() => import('../../pages/PageNotFound'))
const ProfilePage = React.lazy(() => import('../../pages/Profile/Profile'))
const PropertyClassificationPage = React.lazy(() => import('../../pages/PropertyClassification'))
const SicCodeMastersPage = React.lazy(() => import('../../pages/SicCodeMasters'))
const SicCodePage = React.lazy(() => import('../../pages/SicCodes'))
const SicSiloCodePage = React.lazy(() => import('../../pages/SicCodes/SicSiloCodesLoader'))
const SubSiloCodePage = React.lazy(() => import('../../pages/SicCodes/SubSiloCodesLoader'))

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path={paths.login}
          element={
            <React.Suspense fallback={<Loader loading />}>
              <Login />
            </React.Suspense>
          }
        />
        <Route element={<ProtectedRoute />}>
          <Route
            path={paths.home}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <HomePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.basicCost}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <BasicCostPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.accounts}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AccountsPage />
              </React.Suspense>
            }
          />
          <Route element={<AccountLayout />}>
            <Route
              path={paths.accountOverview}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountOverviewPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountAssociatedAccounts}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountAssociatedAccountsPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountPropertyAssetList}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountPropertyAssetListPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountLeasedAssetList}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountLeasedAssetListPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountPayments}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountPaymentsPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountNotes}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountNotesPage />
                </React.Suspense>
              }
            />
            <Route
              path={paths.accountUsers}
              element={
                <React.Suspense fallback={<Loader loading />}>
                  <AccountUsersPage />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path={paths.countySettings}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <CountySettingsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.propertyClassification}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <PropertyClassificationPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.sicCodeMasters}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <SicCodeMastersPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.sicCodes}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <SicCodePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.sicSiloCodes}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <SicSiloCodePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.subSiloCodes}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <SubSiloCodePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.appraiserRegions}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AppraiserRegionsPage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.profile}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <ProfilePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.accountType}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AccountTypePage />
              </React.Suspense>
            }
          />
          <Route
            path={paths.adminUsers}
            element={
              <React.Suspense fallback={<Loader loading />}>
                <AdminUsersPage />
              </React.Suspense>
            }
          />
        </Route>
      </Route>

      <Route element={<Layout isPageNotFound />}>
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader loading />}>
              <PageNotFound />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
