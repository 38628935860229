const paths = {
  accounts: '/accounts',
  accountOverview: '/accounts/:accountId/overview',
  accountAssociatedAccounts: '/accounts/:accountId/associated-accounts',
  accountPropertyAssetList: '/accounts/:accountId/property-asset-list',
  accountLeasedAssetList: '/accounts/:accountId/leased-asset-list',
  accountPayments: '/accounts/:accountId/payments',
  accountNotes: '/accounts/:accountId/notes',
  accountUsers: '/accounts/:accountId/users',
  accountType: '/account-type',
  basicCost: '/basic-cost',
  countySettings: '/county-settings',
  propertyClassification: '/property-classification',
  appraiserRegions: '/appraiser-regions',
  home: '/',
  login: '/login',
  sicCodeMasters: '/sic-code-master',
  sicCodes: '/sic-code-list',
  sicSiloCodes: '/sic-silo-list',
  subSiloCodes: '/sic-sub-list',
  profile: '/profile',
  adminUsers: '/admin-users',
} as const

export type Path = typeof paths[keyof typeof paths]

export default paths
