import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useGlobalContext from '../../hooks/useGlobalContext'
import paths from '../../utils/paths'

export default function ProtectedRoute(): JSX.Element {
  const { user } = useAuth()
  const { getCurrentTaxYearQueryResponse } = useGlobalContext()
  const location = useLocation()

  React.useEffect(() => {
    if (location) {
      getCurrentTaxYearQueryResponse.refetch()
    }
  }, [getCurrentTaxYearQueryResponse, location])

  if (!user) {
    return <Navigate to={paths.login} replace />
  }

  return <Outlet />
}
