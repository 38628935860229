import DashboardIcon from '@mui/icons-material/Dashboard'
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PersonIcon from '@mui/icons-material/Person'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import StorefrontIcon from '@mui/icons-material/Storefront'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import paths from '../../utils/paths'
import {
  goToBasicCostPage,
  goToAccounts,
  goToHomePage,
  goToPropertyClassificationPage,
  goToAppraiserRegionsPage,
  goToAccountTypes,
  goToSicCodesPage,
  goToSicSiloCodesPage,
  goToSubSiloCodesPage,
  goToSicCodeMatersPage,
  goToUsersPage,
} from '../../utils/routeActions'
import RenderDrawerList from './RenderDrawerList'

const drawerWidth = 240

interface Props {
  open: boolean
  onClose(): void
}

export default function NavigationDrawer(props: Props): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const isTablet = useMediaQuery('(max-width:1199px)')

  const itemList = [
    {
      text: t('dashboard'),
      icon: <DashboardIcon />,
      onClick: (): void => {
        goToHomePage(navigate)
        props.onClose()
      },
      selected: location.pathname === '/',
    },
    {
      text: t('basicCost'),
      icon: <MonetizationOnIcon />,
      onClick: (): void => {
        goToBasicCostPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.basicCost,
    },
    {
      text: t('accounts'),
      icon: <StorefrontIcon />,
      onClick: (): void => {
        goToAccounts(navigate)
        props.onClose()
      },
      selected: Boolean(location.pathname.match(startsWithAccountsRegex)),
    },
    {
      text: t('accountType'),
      icon: <StorefrontIcon />,
      onClick: (): void => {
        goToAccountTypes(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.accountType,
    },
    {
      text: t('propertyClassification'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToPropertyClassificationPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.propertyClassification,
    },
    {
      text: t('sicCodeMaster'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToSicCodeMatersPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.sicCodeMasters,
    },
    {
      text: t('sicCode'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToSicCodesPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.sicCodes,
    },
    {
      text: t('sicSiloCode'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToSicSiloCodesPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.sicSiloCodes,
    },
    {
      text: t('subSiloCode'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToSubSiloCodesPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.subSiloCodes,
    },
    {
      text: t('appraiserRegions'),
      icon: <EditLocationAltIcon />,
      onClick: (): void => {
        goToAppraiserRegionsPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.appraiserRegions,
    },
    {
      text: t('adminUsers'),
      icon: <PersonIcon />,
      onClick: () => goToUsersPage(navigate),
      selected: location.pathname === paths.adminUsers,
    },
  ]

  const drawer = (
    <Box sx={{ overflow: 'auto' }}>
      <RenderDrawerList itemList={itemList} />

      <Divider />
    </Box>
  )

  return (
    <Drawer
      variant={isTablet ? 'temporary' : 'permanent'}
      open={props.open}
      onClose={props.onClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        width: drawerWidth,
        ['& .MuiDrawer-paper']: { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      <Toolbar />
      {drawer}
    </Drawer>
  )
}

const startsWithAccountsRegex = new RegExp(`^${paths.accounts}`, 'i')
