import DateFnsAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider, QueryClientConfig } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import Router from './components/Router'
import { SnackbarProvider } from './components/Snackbar'
import { AccountLayoutStateProvider } from './hooks/useAccountLayoutState'
import { AuthProvider } from './hooks/useAuth'
import { GlobalContextProvider } from './hooks/useGlobalContext'
import theme from './utils/theme'

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      cacheTime: 0,
    },
  },
}

const queryClient = new QueryClient(queryClientConfig)

export default function App(): JSX.Element {
  return (
    <React.Suspense fallback="">
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                  <AccountLayoutStateProvider>
                    <GlobalContextProvider>
                      <SnackbarProvider>
                        <Router />
                      </SnackbarProvider>
                    </GlobalContextProvider>
                  </AccountLayoutStateProvider>
                </AuthProvider>
              </ThemeProvider>
            </BrowserRouter>
          </LocalizationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HelmetProvider>
    </React.Suspense>
  )
}
