import { Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface FooterProps {
  text?: string
}

export default function Footer(props: FooterProps): JSX.Element {
  const classes = useStyles()

  return (
    <footer className={classes.text}>
      <Typography  variant="overline">
        {props?.text}
      </Typography>
    </footer>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
}))
