import { NavigateFunction, NavigateOptions } from 'react-router-dom'
import paths from './paths'

export function goToHomePage(navigate: NavigateFunction, options?: NavigateOptions): void {
  navigate(paths.home, options)
}

export function goToBasicCostPage(navigate: NavigateFunction): void {
  navigate(paths.basicCost)
}

export function goToCountySettingsPage(navigate: NavigateFunction): void {
  navigate(paths.countySettings)
}

export function goToPropertyClassificationPage(navigate: NavigateFunction): void {
  navigate(paths.propertyClassification)
}

export function goToAccounts(navigate: NavigateFunction): void {
  navigate(paths.accounts)
}

export function goToLoginPage(navigate: NavigateFunction): void {
  navigate(paths.login, { replace: true })
}

export function goToAppraiserRegionsPage(navigate: NavigateFunction): void {
  navigate(paths.appraiserRegions)
}

export interface GoToOverviewPagesParams {
  accountId: number | string
  taxYear: number
}

function getTaxYearsQueryParams(taxYear: number): string {
  const queryParams = new URLSearchParams(location.search)
  queryParams.set('taxYear', taxYear.toString())

  return queryParams.toString()
}

export function goToAccountOverviewPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountOverviewURI = paths.accountOverview.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountOverviewURI}?${queryParams}`)
}

export function goToAccountAssociatedAccountsPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountOverviewURI = paths.accountAssociatedAccounts.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)


  navigate(`${accountOverviewURI}?${queryParams}`)
}

export function goToAccountPropertyAssetListPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountPropertyAssetListURI = paths.accountPropertyAssetList.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountPropertyAssetListURI}?${queryParams}`)
}

export function goToAccountLeasedAssetListPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountLeasedAssetListURI = paths.accountLeasedAssetList
    .replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountLeasedAssetListURI}?${queryParams}`)
}

export function goToAccountPaymentsPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountPaymentsURI = paths.accountPayments.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountPaymentsURI}?${queryParams}`)
}

export function goToAccountNotesPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountNotesURI = paths.accountNotes.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountNotesURI}?${queryParams}`)
}

export function goToAccountUsersPage(navigate: NavigateFunction, params: GoToOverviewPagesParams): void {
  const accountUsersURI = paths.accountUsers.replace(':accountId', params.accountId.toString())
  const queryParams = getTaxYearsQueryParams(params.taxYear)

  navigate(`${accountUsersURI}?${queryParams}`)
}

export function goToProfile(navigate: NavigateFunction): void {
  navigate(paths.profile)
}

export function goToAccountTypes(navigate: NavigateFunction): void {
  navigate(paths.accountType)
}

export function goToSicCodeMatersPage(navigate: NavigateFunction): void {
  navigate(paths.sicCodeMasters)
}
export function goToSicCodesPage(navigate: NavigateFunction): void {
  navigate(paths.sicCodes)
}

export function goToSicSiloCodesPage(navigate: NavigateFunction): void {
  navigate(paths.sicSiloCodes)
}

export function goToSubSiloCodesPage(navigate: NavigateFunction): void {
  navigate(paths.subSiloCodes)
}

export function goToUsersPage(navigate: NavigateFunction): void {
  navigate(paths.adminUsers)
}
