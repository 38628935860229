import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'

interface Item  {
  text: string
  icon: JSX.Element
  selected?: boolean
  onClick(): void
}

interface Props {
  itemList: Item[]
}

export default function RenderDrawerList(props: Props): JSX.Element {
  return (
    <List>
      {props.itemList.map((item) => (
        <ListItem key={item.text} disablePadding selected={item.selected} onClick={item.onClick}>
          <ListItemButton>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
